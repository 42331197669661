<template>
  <section>
    <top-alternative-section :image="PuntoVentaImage" :title="$t('punto-de-venta.topAlternative.title')"
      :content="$t('punto-de-venta.topAlternative.content')"></top-alternative-section>

    <features-section :title="$t('punto-de-venta.features.title')" :description="$t('punto-de-venta.features.description')" 
      :items="$t('punto-de-venta.features.items')" ></features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import PuntoVentaImage from "../../assets/punto-venta.png";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";

export default {
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      PuntoVentaImage: PuntoVentaImage,
    };
  }
};
</script>
